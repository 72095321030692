.duoimg{
    margin: 0 auto;
    width: 60px;
}
.duotitle{
    margin: 0 auto;
    text-align: center;
    font-size: 1.875rem;
    font-weight: 700;
    color: rgb(30,58,138);
}
.code-card{
    max-width: 400px;
    padding: 20px 30px;
}
.duovideotext{
    color: rgb(26 115 232);
    font-weight: 800;
    font-size: 20px;
}
.dubtn{
    width: 100%;
    border-radius: 5px;
    font-weight: 700;
    font-size: 18px;
    padding: 10px ;
    background-color: rgb(25, 115, 232);
    color: #ffffff;
    border: none;
    outline: none;
}
.dubtn2{
    background-color: #22C55E;
}
.login-frm{
    margin-top: 10px;
    width: 100%;
}
.dubtn img{
    width: 40px;
}
.duotitle2{
    margin: 0 auto;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 700;
    color: rgb(30,58,138);
}
.input-from {
    border: 1px solid rgb(26 115 232);
    width: 100%;
    display: block;
    border-radius: 7px;
    margin-bottom: 10px;
    padding: 7px 10px;
    outline: none;
}
.input-from:focus{
    border: 1px solid rgb(26 115 232);
    outline: none;
}