.home-bg{
    background-color: #01D44B;
    width: 100%;
    min-height: 100vh;
    display: grid;
    place-items: center;
}
.home-card{
    background-color: #ffffff;
    padding: 15px 20px;
    border-radius: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
}
.img-tag{
    width: 5vw;
}
.input-tag{
    border: 1px solid #dddddd;
    padding: 5px 15px;
    border-radius: 5px;
}
.btn-tag{
    display: inline-block;
    background-color: #01D44B;
    color: #ffffff;
    border: none;
    font-weight: 700;
    padding: 8px 25px;
    border-radius: 5px;
}
.form-card{
    background-color: #FBFEF9;
    padding: 15px 20px;
    border-radius: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}
.form-tag{
    width: 100%;
    border: 1px solid #dddddd;
    padding: 5px 15px;
    border-radius: 5px;
}
.btn-skip{
    display: inline-block;
    background-color: #2DA5CC;
    width: 100%;
    color: #ffffff;
    border: none;
    font-weight: 700;
    padding: 8px 25px;
    border-radius: 5px;
}
.code-card{
    background-color: #ffffff;
    padding: 15px 20px;
    border-radius: 10px;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.code-box{
    display: flex;
    gap: 8px;
    width: 100%;
    justify-content: space-between;
}
.code-box:first-child{
    width: 90%;
    padding: 5px 15px;
    border-radius: 5px;
    border: 1px solid #dddddd;
    padding: 5px 15px;
    border-radius: 5px;
}
.code-box .btn-verify{
    display: inline-block;
    background-color: #2DA5CC;
    color: #ffffff;
    border: none;
    font-weight: 700;
    padding: 8px 25px;
    border-radius: 5px;
}
.code-box input{
    width: 100%;
}