.dsf{
    font-size: 14px;
}
.input-container {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 4px;

    width: 100%;
}

.input-prefix {
    display: block;
    font-weight: bold;
    color: #666;
    margin-right: 8px;
    padding: 0 5px;
}

.input-field2 {
    display: block;
    border: none;
    outline: none;
    font-size: 16px;
    color: #666;
    flex: 1;
    padding: 10px 5px;
    background-color: transparent;

}

.input-field::placeholder {
    color: #aaa;
}